/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Subtitle, Title, Text, ContactForm, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--parallax pb--10 pl--25 pr--25 pt--10" name={"[[T00name]]"} style={{"backgroundColor":"#53dbf0"}} layout={"l1"} parallax={true}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Image className="swp-T00-mobile --right" alt={""} src={"https://cdn.swbpg.com/o/2124/8e308098c208425aac7816f98e602e91.svg"} svg={false} sizes="100vw" style={{"maxWidth":45}} srcSet={""} content={null} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box fs--62 mt--25" style={{"marginTop":null}} content={"<span style=\"color: rgb(6, 12, 48); font-style: italic;\">Neváhejte nás kontaktovat&nbsp;</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"7w08e9ye4w8"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"Obratem se Vám ozveme"}>
              </Title>

              <Text className="text-box fs--26" style={{"maxWidth":650}} content={"Po vyplnění Vašich údajů se Vám ozve náš technik na domluvení konkrétního termínu na vyřešení Vašich požadavků<br><br>Případně zanechte recenzi kterou rádi zveřejníme<br>Děkujeme<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--10 pl--25 pr--25 pt--10" name={"[[T00name]]"} style={{"backgroundColor":"#53dbf0"}} layout={"l1"} parallax={true}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <ContactForm className="--shape3" action={"contact"} name={"agduazlb9zc"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"yz0nq6ytyjj"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte svůj e-mail","id":"h8an136s0nv"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"4fl6sqny6ys"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"5uu58vxqxvs"},{"name":"Odeslat","type":"submit","id":"kncjpahmtwh"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" layout={"l1"} name={"cafzedo2on6"}>
          
          <ColumnWrap className="column__flex --right el--2 flex--bottom" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Fit-Okna"}>
              </Title>

              <Text className="text-box fs--22" style={{"maxWidth":334}} content={"Prosetín 2<br>539 76 Prosetín<br>+420 494 909 023<br>+420 703 635 038<br>+420 702 868 880<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" content={"instagram"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}